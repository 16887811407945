import React from 'react';
import {Match} from '@reach/router';
import StepContainer from 'modules/steps/containers/StepContainer';

const StepsPages = () => {
  return (
    <Match path="/:trackSlug/:courseSlug/:stageSlug/:stepSlug">
      {({match}) => <StepContainer {...match} />}
    </Match>
  );
};

export default StepsPages;
